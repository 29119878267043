.chat-input-container{
    width: 100%;
    /* position: absolute; */
    /* display: flex; */
}

.submit-form input {
    width: 100%;
    padding: 13px;
}

.submit-button{
    width: 100px;
    padding: 3px;
    background-color: rgb(92, 204, 157);
    border: none;
    border-radius: 4px;
}

.svg-send-button {
    width: 15px;
    margin: 0 auto;
    fill: white;
}
