.chat-messages-container{
    height: 95%;
    overflow-y: scroll;
}

/* Style the scrollbar */
.chat-messages-container::-webkit-scrollbar {
    width: 8px;
}

.chat-messages-container::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
    border-radius: 8px;
}

.chat-messages-container::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0.1);
    border-radius: 8px;
}

.chat-single-bot-message-container{
    display: flex;
    justify-content: flex-start;
    margin: 10px 10px 10px 0px;
    align-items: center;
}

.chat-single-user-message-container{
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    align-items: center;
}

.avatar-bot-container{
    display: flex;
    align-items: center;
    margin-right: 12px;
    margin-left: 0px;
}

.avatar-user-container{
    display: flex;
    align-items: center;
    margin-right: 12px;
    margin-left: 12px;
}

.avatar{
    background-color: #d8d8d8;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-text{
    color: black;
    margin: 0;
    padding: 0;
    display: block;
}

.chat-assistant-message{
    text-align: left;
    background-color: rgb(55, 107, 126);
    padding: 10px;
    border-radius: 5px;
    color: white;
}

.chat-user-message{
    text-align: right;
    align-items: center;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    color: #585858;
}