.chat-container {
    height: 75vh;
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    position: relative;
    overflow-y: auto;
}

.chat-inner-container{
    background-color: white;;
    height: 90%;
    width: 80%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.chat-header{
    display: none;
}

.footer {
    width: 100%;
    font-style: italic;
}

.footer a {
    color: rgb(55, 107, 126);
}
  