.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  padding-bottom: 10px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
}

.message-box {
  height: 300px;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

select {
  font-size: 1em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: white;
  background-color: #282c34;
  text-align: center;
  border: none; /* Remove the default border */
}

.message {
  margin: 10px;
}

.submit-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
